import { useCallback, useEffect } from 'react';
import { useWriteContract } from 'wagmi'
import ABI from '@/abi.json';
import { CONTRACT_ADDRESS } from '@/utils/constants';
import { useToast } from "@chakra-ui/react";

const useUpdateSBT = (accountInfo: any, id: number | undefined) => {
  const { data:hash , writeContract } = useWriteContract();
  const toast = useToast();
  const submit = useCallback(async (signature: `0x${string}`) => {
    try {
      await writeContract({
        abi: ABI,
        address: CONTRACT_ADDRESS,
        functionName: 'update',
        args: [id, `0x${accountInfo.data}`, signature],
      });
    } catch (error) {
      console.error('Contract submission failed:', error);
    }
  }, [writeContract, accountInfo, id]);

  useEffect(() => {
    if (hash) {
      toast({
        title: "Contract submission successful!",
        status: "success",
      });
    }
  }, [hash, toast]);

  return { submit };
};

export default useUpdateSBT;
