import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AccountContext from "@/context/account";
import { Twin3verifyOtp } from "@/api";

const VendorVerifyPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { setAccount, setAuth } = useContext(AccountContext);
  const [status, setStatus] = useState<string>("Verifying...");

  // 解析 URL 中的 otp
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let otpParam = params.get("otp")?.toString();

    // 必須在內部創建異步函數來使用 await
    const verifyOtp = async () => {
      if (otpParam) {
        const response = await Twin3verifyOtp(otpParam);
        if(!response.address || !response.access_token)
        {
          setStatus("Failed to verify OTP.");
          return;
        }
        const { address, access_token } = response;
        setAccount?.(address);
        setAuth?.(access_token);
        localStorage.setItem("auth", access_token);
        setStatus("OTP verification succeeded");
        history.push("/tasks/1");
      }
      else
      {
        setStatus("OTP not found in URL.");
      }
    };

    verifyOtp();
  }, [setAccount, setAuth, location.search, history]);

  return (
    <div
      className="css-fwlxqg"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <h1>Vendor Verification</h1>
      <p>Status: {status}</p>
    </div>
  );
};

export default VendorVerifyPage;
